
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { close, } from 'ionicons/icons';

// components
import { IonPage, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent,
         IonThumbnail, IonAvatar, IonLabel, IonChip, IonList, IonItem,
         IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
         IonButtons, IonButton, IonIcon, IonModal, IonHeader, IonToolbar, } from '@ionic/vue';
import LoadingSkeleton from '@/components/LoadingSkeleton.vue';

// API services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

export default {
  name: 'BandPostListPage',
  components: { IonPage, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent,
                IonThumbnail, IonAvatar, IonLabel, IonChip, IonList, IonItem,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
                IonButtons, IonButton, IonIcon, IonModal, IonHeader, IonToolbar,
                LoadingSkeleton },
  setup() {
    const { t } = useI18n();
    const { getLocalizedStr, formatDate, getRelativeDate, openBrowser, openImageModal, } = utils();
    const store = useStore();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppPublicData);
    const latestBandPosts = computed(() => store.state.bandPosts);
    const settings = computed(() => store.state.settings);
    const isBandPostModalOpened = ref(false);
    const readMorePost = ref({});

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close,

      // variables
      loading, latestBandPosts, settings,
      isBandPostModalOpened,
      readMorePost,

      // methods
      t, getLocalizedStr,
      formatDate, getRelativeDate,
      openBrowser, openImageModal,

      getMainPostPhoto: (post: any) => {
        if (post.photos.length > 0) {
          return (post.photos.find(p => !p.isVideoThumbnail) || post.photos[0]).url;
        }
        return null;
      }
    }
  }
}
